import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { find, map, set } from "lodash";
import Countdown, { zeroPad } from "react-countdown";
import ApiEngine from "util/ApiEngine";
import {
	ApiKey,
	ApiUrl,
	SessionKey,
	WebUrl,
	TransactionType,
	BankingChannel,
	PanelType,
	DEFAULT_PAGE_SIZE,
} from "util/Constant";
import { setLoginModal } from "redux/AppAction";
import { useTranslation } from "react-i18next";
import { bindMember, setBusy, setIdle,setSpinWheelListModal } from "redux/AppAction";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import VIcon from "components/custom/Icon";
import { themeIcons } from "config/theme";
import { stringIsNullOrEmpty } from '../../util/Util'
import SpinWheelListPopUpModal from "components/template_1/spinWheel/SpinWheelListPopUpModal";

const SpinWheel = ({ isPromoInfo, onClickPromoInfo, promoInfoId }) => {
	const { t, i18n } = useTranslation();
	let _history = useHistory();
	let _location = useLocation();
	const _dispatch = useDispatch();
	const _userData = useSelector((state) => state.authState.userData);
	const memberData = useSelector((state) => state.appState.member);
  const [iframeSrc, setIframeSrc] = useState('');
  var { heartBeat } = useSelector((state) => state.authState);
  
  const [spinWheelEnabled, setSpinWheelEnabled] = useState(false);
  const [showSpinWheelForGuest, setShowSpinWheelForGuest] = useState(false);
  const [shouldShowSpinWheel, setShouldShowSpinWheel] = useState(false);
  const [showSpinWheelListPopup,setShowSpinWheelListPopup] = useState(false);

	useEffect(() => {
		init();
	}, []);

  useEffect(() => {
    init()
  }, [heartBeat]);

  async function init() {
    // Get spinwheel settings
    let responseJson = await ApiEngine.get(
      ApiUrl._API_GET_SPINWHEEL_SETTINGS
    );

    if(responseJson[ApiKey._API_SUCCESS_KEY]){
      console.log('responseJson[ApiKey._API_DATA_KEY]', responseJson[ApiKey._API_DATA_KEY])
      var spinWheelEnabled = false;
      var showSpinWheelForGuestTemp = false;

      if(responseJson[ApiKey._API_DATA_KEY].spinWheelEnabled == "1"){
        spinWheelEnabled = true;
      }

      if(responseJson[ApiKey._API_DATA_KEY].showSpinWheelForGuest == "1"){
        showSpinWheelForGuestTemp = true;
        setShowSpinWheelForGuest(true);
      }

      if(heartBeat && spinWheelEnabled) {
        setShouldShowSpinWheel(true);
      } else if(!heartBeat && spinWheelEnabled && showSpinWheelForGuestTemp) {
        setShouldShowSpinWheel(true);
      } else {
        setShouldShowSpinWheel(false);
      }   
    }
  }

  const SpinWheelIcon = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 240 240"
      // width={240}
      // height={240}
      fill="none"
      {...props}
    >
      <circle cx={120} cy={120} r={120} fill="#292929" />
      <circle
        cx={120.013}
        cy={120}
        r={101.25}
        fill="url(#a)"
        transform="rotate(-45 120.013 120)"
      />
      <path
        fill="#00D5FF"
        d="M206.614 155.877a93.763 93.763 0 0 0 0-71.754L120 120l86.614 35.877Z"
      />
      <path
        fill="#ED0D6D"
        d="M155.877 206.614a93.752 93.752 0 0 0 50.737-50.737L120 120l35.877 86.614Z"
      />
      <path
        fill="#50F"
        d="M84.123 206.614a93.752 93.752 0 0 0 71.754 0L120 120l-35.877 86.614Z"
      />
      <path
        fill="#00A185"
        d="M33.386 155.877a93.75 93.75 0 0 0 50.737 50.737L120 120l-86.614 35.877Z"
      />
      <path
        fill="#9F9F9F"
        d="M33.386 84.123a93.75 93.75 0 0 0 0 71.754L120 120 33.386 84.123Z"
      />
      <path
        fill="#F50"
        d="M84.123 33.386a93.75 93.75 0 0 0-50.737 50.737L120 120 84.123 33.386Z"
      />
      <path
        fill="#FF9100"
        d="M155.877 33.386a93.75 93.75 0 0 0-71.754 0L120 120l35.877-86.614Z"
      />
      <path
        fill="#FFC400"
        d="M206.614 84.123a93.751 93.751 0 0 0-50.737-50.737L120 120l86.614-35.877Z"
      />
      <circle cx={120} cy={120} r={93.75} fill="url(#b)" />
      <path
        fill="url(#c)"
        fillOpacity={0.5}
        d="m120.001 41.925 14.939 42.009 40.268-19.142-19.141 40.269L198.076 120l-42.009 14.939 19.141 40.268-40.268-19.141-14.939 42.009-14.939-42.009-40.269 19.141 19.142-40.268L41.925 120l42.01-14.939-19.142-40.269 40.269 19.142 14.939-42.01Z"
        style={{
          mixBlendMode: "overlay",
        }}
      />
      <path
        fill="#000"
        d="m120.001 95.925 4.606 12.954 12.417-5.903-5.902 12.417L144.076 120l-12.954 4.606 5.902 12.417-12.417-5.902-4.606 12.954-4.607-12.954-12.417 5.902 5.903-12.417L95.926 120l12.954-4.607-5.903-12.417 12.417 5.903 4.607-12.954Z"
        style={{
          mixBlendMode: "overlay",
        }}
      />
      <circle
        cx={120.011}
        cy={120}
        r={11.25}
        fill="url(#d)"
        transform="rotate(-45 120.011 120)"
      />
      <circle cx={120} cy={120} r={9.375} fill="#292929" />
      <defs>
        <radialGradient
          id="b"
          cx={0}
          cy={0}
          r={1}
          gradientTransform="rotate(90 0 120) scale(134.5)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.62} stopOpacity={0} />
          <stop offset={0.85} />
        </radialGradient>
        <radialGradient
          id="c"
          cx={0}
          cy={0}
          r={1}
          gradientTransform="rotate(90 0 120) scale(78.075)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} />
        </radialGradient>
        <linearGradient
          id="a"
          x1={120.013}
          x2={120.013}
          y1={18.75}
          y2={221.25}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEDB32" />
          <stop offset={0.5} stopColor="#FA41C3" />
          <stop offset={1} stopColor="#6D9FDD" />
        </linearGradient>
        <linearGradient
          id="d"
          x1={120.011}
          x2={120.011}
          y1={108.75}
          y2={131.25}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEDB32" />
          <stop offset={0.5} stopColor="#FA41C3" />
          <stop offset={1} stopColor="#6D9FDD" />
        </linearGradient>
      </defs>
    </svg>
  )

	return (
    <>
      {shouldShowSpinWheel && (
        <div id="spin-wheel">
          <style>
            {`
              #spin-wheel {
                position: fixed;
                right: 10px;
                bottom: 150px;
                transform: translate(50%, 50%);
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 10;
                cursor: pointer;
              }

              @media only screen and (max-width: 768px) {
                #spin-wheel {
                  right: 10px;
                  bottom: 200px;
                }
              }

              .wheel-icon {
                width: 80px;
                height: 80px;
                animation: rotate 10s linear infinite;
              }

              .wheel-icon svg {
                width: 100%;
              }

              #spin-wheel-modal iframe {
                width: 100%;
                min-height: 700px;
                height: 100vh;
                border: 0;
              }

              @keyframes rotate {
                from {
                  transform: rotate(0deg);
                }
                to {
                  transform: rotate(360deg);
                }
              }
            `}
          </style>
          <div className="wheel-icon" onClick={() => {
            //_dispatch(setSpinWheelListModal(true));
            if(!heartBeat && showSpinWheelForGuest){
              _dispatch(setLoginModal(true));
            }else{
             _history.push(WebUrl._URL_SPIN_WHEEL_LIST);
             }
          }}>
            <SpinWheelIcon />
          </div>
        </div>
      )}
      <SpinWheelListPopUpModal/>
    </>
	);
};

export default SpinWheel;
