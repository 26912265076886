import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
	ApiKey,
	ApiUrl,
	WebUrl,
	TransactionType,
	TransactionStatus,
} from "../../../util/Constant";
import ApiEngine from "../../../util/ApiEngine";
import {
	setBusy,
	setIdle,
	showMessage,
	showResponseMessage,
	bindCompanyIcon,
} from "../../../redux/AppAction";
import {
	stringIsNullOrEmpty,
	numberWithCurrencyFormat,
} from "../../../util/Util";
import { debounce, map, find, filter, upperCase, orderBy } from "lodash";
import { themeIcons } from "config/theme";
import Spinner from "react-bootstrap/Spinner";
import DateRangeFilter from "components/custom/DateRangeFilter";
import PaginationBar from "../../../components/custom/PaginationBar";

const TransactionHistory = () => {
	let _history = useHistory();
	let _location = useLocation();
	const _dispatch = useDispatch();
	const { t, i18n } = useTranslation();
	const [transactionDetailsActiveTaB, setTransactionDetailsActiveTaB] =
		useState(1);
	const [paginationPageNum, setPaginationPageNum] = useState(0);
	const [isResetCurrentPage, setIsResetCurrentPage] = useState(false);
	const [sortOrder, setSortOrder] = useState("");
	const [loadingFlag, setLoadingFlag] = useState(true);
	const [searchTransactionHistory, setSearchTransactionHistory] = useState([]);
	const [startDate, setStartDate] = useState(
		_location?.state?.date
			? moment(_location.state.date.split(t("TO"))[0], "DD-MM-YYYY")
			: moment().startOf("isoWeek")
	);
	const [referralBonus, setReferralBonus] = useState([]);
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);


	useEffect(() => {
		init()
	}, []);

	function init() {
		GetReferralBonus();

	}


	async function GetReferralBonus() {
		var response = await ApiEngine.get(ApiUrl._API_GET_MEMBER_REFERRAL_BONUS);
		if (response[ApiKey._API_SUCCESS_KEY]) {
			let temp = response[ApiKey._API_DATA_KEY];
			setReferralBonus(temp);

		}
		setLoadingFlag(false);
	}

	async function ClaimReferralBonus() {
		_dispatch(setBusy());
		setIsSubmitLoading(true)
		var response = await ApiEngine.get(ApiUrl._API_CLAIM_MEMBER_REFERRAL_BONUS);
		if (response[ApiKey._API_SUCCESS_KEY]) {
			let temp = response[ApiKey._API_DATA_KEY];

		}
		GetReferralBonus()
		_dispatch(
			showResponseMessage(
				response[ApiKey._API_SUCCESS_KEY],
				t(response[ApiKey._API_MESSAGE_KEY])
			)
		);

		setIsSubmitLoading(false)
		_dispatch(setIdle());
	}

	return (
		<div className="t3-downline-modal-body pt-0 mb-4" id="deposit-id">

			<div className="t3-downline-table-body">
				<div className="t3-table-container">
					<table className="t3-custom-table">
						<thead>
							<tr>
								<th>{t("DOWNLINE")}</th>
								<th>{t("BONUS_AMOUNT")}</th>
								<th>{t("STATUS")}</th>
								<th>{t("CLAIMED_TIME")}</th>
								<th>{t("DEPOSIT_TIME")}</th>

							</tr>
						</thead>
						<tbody>
							{referralBonus &&
								referralBonus.length != 0 ? (
									referralBonus.map((data, index) => {

									var statusHtml = "";
									switch (data["isClaimed"]) {
										case true:
											statusHtml = (
												<span className="font12 text-customgreen f-weight-500">
													{t("CLAIMED")}
												</span>
											);
											break;
										case false:
											statusHtml = (
												<span className="font12 text-customred f-weight-500">
													{t("UNCLAIMED")}
												</span>
											);
											break;
									}

									return (
										<tr key={index}>
											<td>{data["username"]}</td>
											
											<td>
												{numberWithCurrencyFormat(parseFloat(data.bonusAmount), 2)}
											</td>
											<td>{statusHtml}</td>
											<td>
												{data["claimedDate"]}
											</td>
											<td>
												{data["createdTime"]}
											</td>
										</tr>
									);
								})
							) : loadingFlag ? (
								<tr>
									<td colSpan={5}>
										<div
											style={{
												textAlign: "center",
												marginTop: "25px",
												marginBottom: "20px",
											}}
										>
											<Spinner
												animation="border"
												role="status"
												size="sm"
												style={{
													verticalAlign: "middle",
													width: "2rem",
													height: "2rem",
												}}
											>
												<span className="sr-only">{t("LOADING")}...</span>
											</Spinner>
										</div>
									</td>
								</tr>
							) : (
								<tr>
									<td colSpan={5}>{t("NO_DATA_FOUND")}</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>
			{/*{paginationPageNum > 1 && (*/}
			{/*	<PaginationBar*/}
			{/*		totalPages={paginationPageNum}*/}
			{/*		retrieveDataRecords={getTransactionData}*/}
			{/*		isResetCurrentPage={isResetCurrentPage}*/}
			{/*		setIsChangeTab={setIsResetCurrentPage}*/}
			{/*	/>*/}
			{/*)}*/} 
			{referralBonus.some(x => !x.isClaimed) &&
				<div className="text-center mt-3">
					<button className="t3-custom-light-btn" onClick={() => { ClaimReferralBonus() }} disabled={!referralBonus.some(x => !x.isClaimed) || isSubmitLoading}>
						{isSubmitLoading ? (
							<Spinner
								className="dynamic-table-spinner"
								animation="border"
								role="status"
								size="sm"
								style={{
									verticalAlign: "middle",
									width: "1.5rem",
									height: "1.5rem",
									marginLeft: 3,
								}}
							></Spinner>
						) : (
							t("CLAIM")
						)}
					</button>
				</div>
			}
		</div>
	);
};

export default TransactionHistory;
