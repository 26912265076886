import { themeIcons } from "config/theme";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import VIcon from "components/custom/Icon";
import { useDispatch, useSelector } from "react-redux";
import PaginationBar from "../../../components/custom/PaginationBar";
import { ApiKey, ApiUrl } from "util/Constant";
import ApiEngine from "util/ApiEngine";
import { showResponseMessage, bindMember, setMyProfileModal,setSpinWheelListModal } from "redux/AppAction";
import { map, find } from "lodash";
import { numberWithCurrencyFormat } from "../../../util/Util";



const SpinWheelListPopUpModal = (props) => {
  var { t } = useTranslation();
  var _dispatch = useDispatch();

  const [paginationPageNum, setPaginationPageNum] = useState(10);
	const [isResetCurrentPage, setIsResetCurrentPage] = useState(false);
  const walletsDetails = useSelector((state) => state.appState.walletsDetails);
  var { heartBeat } = useSelector((state) => state.authState);


  const spinWheelListModal = useSelector((state) => state.appState.spinWheelListModal);
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [spinWheelList, setSpinWheelList] = useState([]);
  const [shouldShowSpinWheel, setShouldShowSpinWheel] = useState(false);


  //dummy data
  const cardsData = [
    { spinWheelId: "1232", campaignName: "Welcome Bonus", expiryDate:"4 days 23 mins"},
    { spinWheelId: "1232", campaignName: "Welcome Bonus", expiryDate:"4 days 23 mins"},
    { spinWheelId: "1232", campaignName: "Welcome Bonus", expiryDate:"4 days 23 mins"},
    { spinWheelId: "1232", campaignName: "Welcome Bonus", expiryDate:"4 days 23 mins"},
    { spinWheelId: "1232", campaignName: "Welcome Bonus", expiryDate:"4 days 23 mins"},
    { spinWheelId: "1232", campaignName: "Welcome Bonus", expiryDate:"4 days 23 mins"},
    { spinWheelId: "1232", campaignName: "Welcome Bonus", expiryDate:"4 days 23 mins"},
    { spinWheelId: "1232", campaignName: "Welcome Bonus", expiryDate:"4 days 23 mins"},
    { spinWheelId: "1232", campaignName: "Welcome Bonus", expiryDate:"4 days 23 mins"},
    { spinWheelId: "1232", campaignName: "Welcome Bonus", expiryDate:"4 days 23 mins"},
  ];

  useEffect(() => {
		if (shouldShowSpinWheel && spinWheelListModal) {
			getMySpins();

			// Create interval for spin wheel heartbeat
			const interval = setInterval(() => {
				// Call the spin wheel heartbeat function
				getMySpins();
			}, 5000);

			return () => {
				clearInterval(interval);
			};
		}
	}, [shouldShowSpinWheel]);

  useEffect(() => {
		if (heartBeat) {
			//setIsLogin(true);
			init();
		}
	}, [heartBeat]);

  async function init() {
		// Get spinwheel settings
		let responseJson = await ApiEngine.get(
		ApiUrl._API_GET_SPINWHEEL_SETTINGS
		);
	
		if(responseJson[ApiKey._API_SUCCESS_KEY]){
			var spinWheelEnabled = false;
			var showSpinWheelForGuest = false;
		
			if(responseJson[ApiKey._API_DATA_KEY].spinWheelEnabled == "1"){
				spinWheelEnabled = true;
			}
		
			if(responseJson[ApiKey._API_DATA_KEY].showSpinWheelForGuest == "1"){
				showSpinWheelForGuest = true;
			}
		
			if(heartBeat && spinWheelEnabled) {
				setShouldShowSpinWheel(true);
			} else if(!heartBeat && spinWheelEnabled && showSpinWheelForGuest) {
				setShouldShowSpinWheel(true);
			}
		}
	}

  async function getMySpins() {
		console.log('getMySpins1')

		let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_SPINS);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			setSpinWheelList(responseJson[ApiKey._API_DATA_KEY]);
		} else {
			_dispatch(
				showResponseMessage(false, "ERROR_LOADING_MY_SPINS")
			);
		}

	}


  return (
    <div>

      <Modal
        id="t3-custom-modal"
        className="modal-custom-fullscreen"
        contentClassName={"t3-custom-modal t3-custom-modal-with-back-btn min-height-600px"}
        fade={false}
        //isOpen={spinWheelListModal}
        isOpen={false}
        toggle={() => {
          _dispatch(setSpinWheelListModal(false))
        }}
      >
        <ModalHeader>
          <div className="first">
            <div
              onClick={() => {
                // setManageProfileShow(false);
                _dispatch(setSpinWheelListModal(false))

              }}
            >
              <VIcon
                icon={themeIcons.angleLeft}
                className="vicon theme-icon-size-20"
              />
            </div>
            <div>{t("SPIN_WHEEL_TOKENS")}</div>
          </div>
          <div className="second">
            <div
              className="t3-close-custom-modal"
              onClick={() => _dispatch(setSpinWheelListModal(false))}
            >
              <VIcon icon={themeIcons.close} className="vicon" />
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="t3-custom-modal-container">
            <div className="custom-body edit-profile" >


            <div className="spin-wallet-container">
             <div className="wallet-row">
             <div className="wallet-info">
      <img
        src={require("../../../assets/img/new_icons/vector-point.svg")}
        alt="img-wallet"
      />
      <div>
        <div> {`${t("WALLET_BALANCE")}:`}</div>
        <div
          style={{
            fontWeight: "Bold",
            color: "var(--theme-color-bg-btn-submit)",
          }}
        >
          {numberWithCurrencyFormat(
            walletsDetails.currentWalletBalance,
            2,
            true
           )}
           </div>
         </div>
         </div>
        {/* <button
         className="t3-custom-light-btn record-button"
           onClick={() => {
        // click function
           }}
          >
           <img
           src={require("../../../assets/img/new_icons/record.svg")}
           alt="img-wallet"
            />
            <div>{t("RECORD")}</div>
             </button> */}
             </div>
            </div>
                <div className="t3-alink" style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem",
                      padding:"20px 0px",
                      textDecoration:"none"
                     }}>
								<i className="fa fa-clock"></i>
                <span>{t("SPINS_WHEEL_REMINDER")}</span>
                </div>

             <> <div className="spin-wheel-card-container">
               {cardsData.map((card, index) => (
               <div className="spin-wheel-card" key={index}>
                <div style={{fontWeight:"Bold",color:"var(--theme-color-bg-btn-submit)"}}>
                  {`${t("SPIN_WHEEL")} #${card.spinWheelId}`}
                </div>
                <div>
                  {`${t("CAMPAIGN")}: ${card.campaignName}`}
                </div>
                <div className="time-container">
								<i className="fa fa-clock" ></i>
                <span className="t3-alink" style={{textDecoration:"none"}}>{card.date}</span>
                </div>
                <button
                className="t3-custom-light-btn"
                onClick={() => {
									  ////click function
								}}>
                <div>{t("SPIN_NOW")}</div>
                </button>

                </div>
                ))}
                </div>
              
        {paginationPageNum > 1 && (
				<PaginationBar
					totalPages={paginationPageNum}
					retrieveDataRecords={cardsData}
					isResetCurrentPage={isResetCurrentPage}
					setIsChangeTab={setIsResetCurrentPage}
				/>
			  )}
        </>


                {!isLoadingList && spinWheelList && spinWheelList.length == 0 && (
										<div className="t3-spinwheel-token-item empty">
											<div className="d-flex align-items-center justify-content-center">
												<div className="t3-spinwheel-token-details">
													<div style={{ color: "var(--theme-color-text-placeholder)" }}>{t("NO_SPIN_WHEEL_TOKENS")}</div>
												</div>
											</div>
										</div>
									)}

            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>

  );
};

export default SpinWheelListPopUpModal;
